// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-explore-js": () => import("./../../../src/pages/explore.js" /* webpackChunkName: "component---src-pages-explore-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-location-js": () => import("./../../../src/pages/location.js" /* webpackChunkName: "component---src-pages-location-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */)
}

